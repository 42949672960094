export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
        "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Please signin to <strong>Roojai Agent Portal</strong> and make your day.</p>"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username / Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
        "forgotPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>No problem! We'll send you a link to reset it. Enter the email address you use to sign in to <strong>Agent portal</strong>.</small>"])},
        "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reset link"])},
        "checkYourInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox"])},
        "checkYourInboxDesc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We've just emailed instructions and a reset password link to <strong>", _interpolate(_named("email")), "</strong>. It might take a few minutes to arrive."])},
        "backToSignin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to sign-in"])},
        "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred, Please try again."])},
        "activateNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate new user"])},
        "activateNewUserDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>Please input your user email</small>"])},
        "getStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GET START"])},
        "otpVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Verification"])},
        "otpVerificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>Choose your OTP destination Where do you want to recieve your OTP code?</small>"])},
        "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND OTP"])},
        "verifyOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERIFY OTP"])},
        "resendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESEND OTP"])},
        "verifyOTPDesc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<small>Your reference number (Ref no.): <strong>", _interpolate(_named("otpRef")), "</strong></small>"])},
        "otpNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Number"])},
        "verifyOTPSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>You have successfully authenticated via OTP</small>"])},
        "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SET NEW PASSWORD"])},
        "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new password"])},
        "createNewPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>Use a minimum of 12 characters, including uppercase lette, lowercase letter and numbers</small>"])},
        "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])}
      },
      "th": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยินดีต้อนรับ"])},
        "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>กรุณาเข้าสู่ระบบ <strong>เอเจ้นท์</strong> และเริ่มการขาย</p>"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้ใช้ / อีเมล์"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
        "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
        "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลืมรหัสผ่าน"])},
        "forgotPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>ไม่มีปัญหา เราจะส่งลิงก์รีเซ็ตรหัสผ่านไปให้ท่าน โปรดระบุอีเมลที่ท่านใช้เข้าสู่ระบบ <strong>Agent portal</strong>.</small>"])},
        "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งลิงก์รีเซ็ตรหัสผ่าน"])},
        "checkYourInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบกล่องจดหมายของคุณ"])},
        "checkYourInboxDesc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เราเพิ่งส่งอีเมลแจ้งขั้นตอนพร้อมลิงก์สำหรับรีเซ็ตรหัสผ่านไปที่ <strong>", _interpolate(_named("email")), "</strong> ทั้งนี้อาจใช้เวลาสักครู่ก่อนที่ท่านจะได้รับอีเมลดังกล่าว"])},
        "backToSignin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปที่ลงชื่อเข้าใช้"])},
        "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง"])},
        "activateNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งานผู้ใช้ใหม่"])},
        "activateNewUserDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>กรุณาใส่อีเมลของคุณ</small>"])},
        "getStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มต้น"])},
        "otpVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตรวจสอบรหัส OTP"])},
        "otpVerificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>เลือกช่องทางการรับ OTP ของคุณ คุณต้องการรับรหัส OTP ช่องทางใด</small>"])},
        "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งรหัสยืนยัน"])},
        "verifyOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน OTP"])},
        "resendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่ง OTP อีกครั้ง"])},
        "verifyOTPDesc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<small>หมายเลขอ้างอิงของคุณ (หมายเลขอ้างอิง): <strong>", _interpolate(_named("otpRef")), "</strong></small>"])},
        "otpNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลข OTP"])},
        "verifyOTPSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>คุณตรวจสอบสิทธิ์ผ่าน OTP สำเร็จแล้ว</small>"])},
        "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งรหัสผ่านใหม่"])},
        "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างรหัสผ่านใหม่"])},
        "createNewPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>ใช้อักขระอย่างน้อย 12 ตัว รวมทั้งอักษรตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก และตัวเลข</small>"])},
        "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันรหัสผ่าน"])}
      },
      "zh": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
        "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Please signin to <strong>Roojai Agent Portal</strong> and make your day.</p>"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username / Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
        "forgotPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>No problem! We'll send you a link to reset it. Enter the email address you use to sign in to <strong>Agent portal</strong>.</small>"])},
        "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reset link"])},
        "checkYourInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox"])},
        "checkYourInboxDesc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We've just emailed instructions and a reset password link to <strong>", _interpolate(_named("email")), "</strong> . It might take a few minutes to arrive."])},
        "backToSignin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to sign-in"])},
        "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred, Please try again."])},
        "activateNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate new user"])},
        "activateNewUserDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>Please input your user email</small>"])},
        "getStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GET START"])},
        "otpVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Verification"])},
        "otpVerificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>Choose your OTP destination Where do you want to recieve your OTP code?</small>"])},
        "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND OTP"])},
        "verifyOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERIFY OTP"])},
        "resendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESEND OTP"])},
        "verifyOTPDesc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<small>Your reference number (Ref no.): <strong>", _interpolate(_named("otpRef")), "</strong></small>"])},
        "otpNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Number"])},
        "verifyOTPSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>You have successfully authenticated via OTP</small>"])},
        "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SET NEW PASSWORD"])},
        "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new password"])},
        "createNewPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<small>Use a minimum of 12 characters, including uppercase lette, lowercase letter and numbers</small>"])},
        "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])}
      }
    }
  })
}
