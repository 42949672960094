<style>
/* add style to signin.scss */
#signin .form-control{
  border-radius: 0.5rem;
}

#signin .page-header{
  height: 450px;
}

#signin #signin-frm{
  margin-top:-200px;
}

@media (max-height: 728px) {
    #signin #signin-frm{
      margin-top:-240px;
    }
}

#signin .forgot-password-link {
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 700;
    color: #4595d2;
    /* color: #17c1e8; */
}

#signin .disabled-link {
    cursor: not-allowed;
}

a.otp-destination {
  display: block;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 10px;
  color: #eee;
  text-align: left;
  margin-bottom: 15px;
  font-size: 14px;
}
a.otp-destination:hover {
  background: #fff;
  color: #298be0;
}
a.otp-destination.selected {
  background: #fff;
  color: #015cae;
  border: 1px solid rgba(182, 176, 176, 0.952)
}
</style>
<template>
  <div id="signin">
    <nav class="navbar navbar-expand-lg position-absolute top-0 z-index-3 w-100 shadow-none my-3  navbar-transparent mt-5">
      <div class="container">
        <div class="navbar-brand font-weight-bolder ms-lg-0 ms-3 text-white" >
          {{$t("pageTitle")}}
        </div>
        <selectLang :pageName="'Signin'"></selectLang>
      </div>
    </nav>
    <!-- End Navbar -->

    <section class="mb-8">
      <div class="page-header align-items-start pt-5 pb-11 m-1 m-sm-3 m-md-3 border-radius-lg bg-pk">
        <span class="mask bg-gradient-dark opacity-2"></span>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center mx-auto">
              <h1 class="text-white mb-2 mt-5">{{$t("welcome")}}</h1>
              <p class="text-lead text-white" v-html="$t('pleaseSignIn')"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="container" id="signin-frm">
        <div class="row">
          <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <div class="card z-index-0">
              <div class="card-header text-center pt-4">
                <div class="col-4 mx-auto">
                  <img src="img/roojai-logo.svg" alt="Roojai" />
                </div>
              </div>
              <div id="signin" class="card-body" v-if="isShowSignin">
                <form role="form text-left" @submit.prevent="signIn">
                
                  <div class="mb-4">
                    <input type="email" id="email" v-model.trim="email" class="form-control" :placeholder="$t('username')" aria-label="Email" aria-describedby="email-addon">
                  </div>
                  <div class="mb-5">
                    <input type="password" v-model.trim="password" id="pass" class="form-control" :placeholder="$t('password')" aria-label="Password" aria-describedby="password-addon">
                  </div>
                  
                  <div class="form-check form-switch mx-3" v-if="false">
                    <input class="form-check-input" type="checkbox" id="rememberMe" checked="">
                    <label class="form-check-label" for="rememberMe">Remember me</label>
                  </div>

                  <div class="text-danger text-center mt-3" v-if="responseStatus === 401">
                    <small>{{ responseData }}</small>
                  </div>
            
                  <div class="text-center">
                    <button type="submit" :disabled="isProcessing || !email || !password" class="btn bg-gradient-primary w-100 my-3 mb-2" @click="signIn(); eventTracking('login', 'login', 'Portal');">
                      {{$t('signin')}} <span class="spinner-border spinner-border-sm" v-if="isProcessing" role="status" aria-hidden="true"></span>
                    </button>
                    <p class="text-sm mt-3 mb-0"><a href="javascript:void(0);" class="forgot-password-link" @click="isShowSignin = false; isActivateEmail = false; isShowForfotPassword = true;">{{$t('forgotPassword')}}</a></p>
                    <p class="text-sm mt-3 mb-0"><a href="javascript:void(0);" class="forgot-password-link" @click="isShowSignin = false; isShowForfotPassword = false; isActivateEmail = true;">{{$t('activateNewUser')}}</a></p>
                  </div>
                  
                </form>
              </div>
              <div id="forgot-passowrd" class="card-body" v-if="isShowForfotPassword">
                <div id="input-reset-link" v-if="!isShowCheckYourInbox"> 
                  <form role="form text-left" @submit.prevent="submitForgotPassword">
                  
                    <p><strong>{{$t('forgotPassword')}}</strong></p>
                    <p v-html="$t('forgotPasswordDesc')"></p>
                    <div class="mb-4">
                      <input type="email" id="email-forgot-password" v-model.trim="emailInput" class="form-control" :placeholder="$t('username')">
                    </div>
              
                    <div class="text-danger text-center mt-3" v-if="isShowErrorMessage">
                      <small>{{ $t('errorMessage') }}</small>
                    </div>

                    <div class="text-center">
                      <button type="submit" :disabled="isProcessingForgotPassword || !emailInput" class="btn bg-gradient-info w-100 my-3 mb-2" @click="submitForgotPassword(); eventTracking('login', 'Forgot_password', 'Portal');">
                        {{$t('sendResetLink')}} <span class="spinner-border spinner-border-sm" v-if="isProcessingForgotPassword" role="status" aria-hidden="true"></span>
                      </button>

                      <p class="text-sm mt-3 mb-0"><a href="javascript:void(0);" class="forgot-password-link" :class="{'disabled-link': isProcessingForgotPassword}" @click="backToSignin()">{{$t('backToSignin')}}</a></p>
                    </div>
                  </form>
                </div>
                <div id="check-your-inbox" v-if="isShowCheckYourInbox">
                    <p><strong>{{$t('checkYourInbox')}}</strong></p>
                    <p v-html="$t('checkYourInboxDesc', {email: emailInput})"></p>

                    <div class="text-center">
                      <button type="button" class="btn bg-gradient-info w-100 my-3 mb-2" @click="backToSignin()">
                        {{$t('backToSignin')}} <span class="spinner-border spinner-border-sm" v-if="isProcessing" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                </div>
              </div>

              <!-- Activate new user -->
              <div id="activate-user" class="card-body" v-if="isActivateEmail">
                <div v-if="isShowActivateEmail"> 
                    <p><strong>{{$t('activateNewUser')}}</strong></p>
                    <p v-html="$t('activateNewUserDesc')"></p>
                    <div class="mb-4">
                      <input type="email" class="form-control" v-model.trim="emailInput" :placeholder="$t('username')">
                    </div>
              
                    <div class="text-danger text-center mt-3" v-if="isShowErrorMessage">
                      <small>{{ $t('errorMessage') }}</small>
                    </div>

                    <div class="text-center">
                      <button type="submit" :disabled="isProcessingForgotPassword || !emailInput" class="btn bg-gradient-info w-100 my-3 mb-2" @click="verifyNewUser(); eventTracking('login', 'Activate_user', 'Portal');">
                        {{$t('getStart')}} <span class="spinner-border spinner-border-sm" v-if="isProcessingForgotPassword" role="status" aria-hidden="true"></span>
                      </button>

                      <p class="text-sm mt-3 mb-0"><a href="javascript:void(0);" class="forgot-password-link" :class="{'disabled-link': isProcessingForgotPassword}" @click="backToSignin()">{{$t('backToSignin')}}</a></p>
                    </div>
                </div>
                <div id="send-otp" v-if="isSendOTP">
                    <p><strong>{{$t('otpVerification')}}</strong></p>
                    <p v-html="$t('otpVerificationDesc')"></p>
                    <div>
                      <a v-if="emailOTP != null" href="javascript:void(0)" @click="selectedMobile=false; selectedEmail=true;" v-bind:class="{'selected' : selectedEmail}" disabled="disabled" data-selenium-name="email-otp" class="otp-destination" style="overflow-wrap: break-word;"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg> 
                        <span>{{emailOTP.display}}</span>
                      </a>
                      <a v-if="phoneOTP != null" href="javascript:void(0)" @click="selectedMobile=true; selectedEmail=false;" v-bind:class="{'selected' : selectedMobile}" disabled="disabled" data-selenium-name="mobile-otp" class="otp-destination" style="overflow-wrap: break-word;"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>
                        <span>{{phoneOTP.display}}</span>
                      </a>
                    </div>
                    <div class="text-center">
                      <button type="button" :disabled="isProcessingForgotPassword" class="btn bg-gradient-info w-100 my-3 mb-2" @click="sendOTP(false)">
                        {{$t('sendOTP')}} <span class="spinner-border spinner-border-sm" v-if="isProcessingForgotPassword" role="status" aria-hidden="true"></span>
                      </button>
                      <p class="text-sm mt-3 mb-0"><a href="javascript:void(0);" class="forgot-password-link" :class="{'disabled-link': isProcessingForgotPassword}" @click="backToSignin()">{{$t('backToSignin')}}</a></p>
                    </div>
                </div>
                <div id="verify-otp" v-if="isVerifyOTP">
                    <p><strong>{{$t('otpVerification')}}</strong></p>
                    <p v-html="$t('verifyOTPDesc', {otpRef: otpRef})"></p>
                    <div class="mb-4">
                      <input type="email" class="form-control" v-model.trim="otpNumber" :placeholder="$t('otpNumber')" maxlength="6">
                    </div>

                    <div class="text-danger text-center mt-3" v-if="isShowErrorMessage">
                      <small>{{ $t('errorMessage') }}</small>
                    </div>

                    <div class="text-center">
                      <button type="button" :disabled="isProcessingForgotPassword" class="btn bg-gradient-info w-100 my-3 mb-2" @click="verifyOTP()">
                        {{$t('verifyOTP')}} <span class="spinner-border spinner-border-sm" v-if="isProcessingForgotPassword && !clickResend" role="status" aria-hidden="true"></span>
                      </button>
                      <p class="text-sm mt-3 mb-0"><a href="javascript:void(0);" class="forgot-password-link" :class="{'disabled-link': isProcessingForgotPassword || clickResend || !showResendOTP}" @click="clickResend = true; sendOTP(true)">{{$t('resendOTP')}}</a><span class="spinner-border spinner-border-sm" v-if="clickResend" role="status" aria-hidden="true"></span></p>
                    </div>
                </div>
                <div class="text-center" id="verify-otp-success" v-if="isVerifyOTPSuccess">
                    <p><strong>{{$t('otpVerification')}}</strong></p>
                    <p v-html="$t('verifyOTPSuccessDesc')"></p>
                    <svg viewBox="0 0 512 512" width="70" height="70" fill="#82d616"><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>

                    <div>
                      <button type="button" class="btn bg-gradient-info w-100 my-3 mb-2" @click="setNewPassword()">
                        {{$t('setNewPassword')}} <span class="spinner-border spinner-border-sm" v-if="isProcessing" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                </div>
                <div id="set-new-password" v-if="isSetNewPassword">
                    <p><strong>{{$t('createNewPassword')}}</strong></p>
                    <p v-html="$t('createNewPasswordDesc')"></p>
                  <div class="mb-3">
                      <input type="password" v-model.trim="newPassword" class="form-control" :placeholder="$t('password')">
                  </div>
                  <div class="mb-3">
                      <input type="password" v-model.trim="confirmNewPassword" class="form-control" :placeholder="$t('confirmPassword')">
                  </div>
                  <div class="text-danger text-center mt-3" v-if="isShowErrorMessage">
                      <small>{{ $t('errorMessage') }}</small>
                    </div>
                    <div class="text-center">
                      <button type="button" class="btn bg-gradient-info w-100 my-3 mb-2" @click="confirmedNewPassword()">
                        {{$t('setNewPassword')}} <span class="spinner-border spinner-border-sm" v-if="isProcessing" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                </div>
                <div class="text-center" id="set-completed" v-if="isSetNewPasswordCompleted">
                    <p><strong>{{$t('otpVerification')}}</strong></p>
                    <p v-html="$t('verifyOTPSuccessDesc')"></p>
                    <svg viewBox="0 0 512 512" width="70" height="70" fill="#82d616"><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>

                    <div>
                      <button type="button" class="btn bg-gradient-info w-100 my-3 mb-2" @click="backToSignin()">
                        {{$t('backToSignin')}} <span class="spinner-border spinner-border-sm" v-if="isProcessing" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                </div>
              </div>
              <!-- Activate new user -->

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import mixin from "@/components/Mixin";
import Global from "@/stores/GlobalVariables.js";
import selectLang from "@/components/SelectLang";

export default {
  mixins: [mixin],
  name: "SignIn",
  components: { selectLang },
  data: function() {
    return {
      Global,
      email: "",
      password: "",
      responseStatus: 0,
      isProcessing: false,
      responseData: "",
      emailInput: "",
      isShowSignin: true,
      isShowForfotPassword: false,
      isProcessingForgotPassword: false,
      isShowCheckYourInbox: false,
      isShowErrorMessage: false,
      isActivateEmail: false,
      isSendOTP: false,
      isVerifyOTP: false,
      emailOTP: null,
      phoneOTP: null,
      selectedMobile: false,
      selectedEmail: true,
      otpRef: "",
      otpNumber: "",
      isVerifyOTPSuccess: false,
      isShowActivateEmail: true,
      isSetNewPassword: false,
      newPassword: "",
      confirmNewPassword: "",
      isSetNewPasswordCompleted: false,
      showResendOTP: false,
      encryptedId: null,
      clickResend: false
    };
  },
  methods: {
    signIn: function() {
      var vm = this;
      if (vm.isProcessing) {
        return true;
      }

      vm.isProcessing = true;

      axios
        .post(Global.apiUrl + "/login", {
          username: this.email,
          password: this.password
        })
        .then(function(response) {
          Global.jwt = response.data.token;
          Global.serverTime = response.data.serverTime;
          Global.email = vm.email;
          Global.authority = JSON.parse(window.atob(Global.jwt.split(".")[1])).authority;
          if(Global.authority != 'API_USER')
          {
            axios.defaults.headers.common["Authorization"] =
            "Bearer " + Global.jwt;
            vm.getSystemConfiguration(async function() {
              await vm.getBrokerDetails(JSON.parse(window.atob(Global.jwt.split(".")[1])).reptId);
              vm.isProcessing = false;
              vm.$router.push("/home");
            });
          }else{
            vm.logActivity(vm.email, "Login", "login fail", null);
            vm.isProcessing = false;
            vm.responseStatus = 401;
            vm.responseData = "Invalid username or password";
          }          
        })
        .catch(function(error) {
          vm.logActivity(vm.email, "Login", "login fail", null);
          vm.isProcessing = false;
          vm.responseStatus = error.response.status;
          if (vm.responseStatus === 401) {
            vm.responseData = error.response.data || "Invalid username or password";
          }
        });
    },
    submitForgotPassword: async function(){
      this.logActivity(this.emailInput, "Forgot password", "Submit forgot password", null);
      if(this.isProcessingForgotPassword){ 
        return true;
      }
      this.isProcessingForgotPassword = true;
      await this.sfMcsApiUserLogin();
      var response = await axios.get(Global.apiUrl + "/broker-details/forgotPassword?email=" + this.emailInput + "&lang=" + Global.locale, {
        headers : {
          Authorization : "Bearer " + Global.apiUser.jwt
        }
      })
      if(response.data.success){
        this.isShowCheckYourInbox = true;
        this.isShowErrorMessage = false;
      }else{
        this.isShowErrorMessage = true;
      }
      this.isProcessingForgotPassword = false;
    },
    backToSignin: function(){
      this.isShowSignin = true; 
      this.isShowForfotPassword = false;
      this.isProcessingForgotPassword = false;
      this.emailInput = "";
      this.isShowCheckYourInbox = false;
      this.isShowErrorMessage = false;
      this.isActivateEmail = false;
      this.isSendOTP = false;
      this.isVerifyOTP = false;
      this.isVerifyOTPSuccess = false;
      this.otpNumber = "";
      this.otpRef = "";
      this.isSetNewPasswordCompleted = false;
      this.isShowActivateEmail = true;
      this.newPassword = "";
      this.confirmNewPassword = "";
      this.encryptedId = null;
    },
    verifyNewUser: async function() {
      if(this.isProcessingForgotPassword){ 
        return true;
      }
      this.isShowErrorMessage = false;
      this.isProcessingForgotPassword = true;
      await this.sfMcsApiUserLogin();
      var response = await axios.get(Global.apiUrl + "/broker-details/verifyNewUser?email=" + this.emailInput, {
        headers : {
          Authorization : "Bearer " + Global.apiUser.jwt
        }
      });
      if (response.data.success === true) {
        this.emailOTP = response.data.email;
        this.phoneOTP = response.data.phoneNumber;
        this.encryptedId = response.data.encryptedId;
        this.isShowActivateEmail = false
        this.isSendOTP = true;
      } else {
        this.isShowErrorMessage = true;
      }
      this.isProcessingForgotPassword = false;
    },
    sendOTP: async function(resendFlag) {
      if(this.isProcessingForgotPassword){ 
        return true;
      }
      var vm = this;
      this.logActivity(this.emailInput, resendFlag===false?"Send OTP":"Resend OTP", "Submit send OTP", this.encryptedId);
      this.showResendOTP = false;
      var req={};
      if (this.selectedMobile === true) {
        req = { destination: vm.phoneOTP.encrypted, isMobile: true };
      } else {
        req = { destination: vm.emailOTP.encrypted, isMobile: false };
      }
      try {
        this.isProcessingForgotPassword = true;
        var response = await axios.post(Global.apiUrl + "/broker-details/sendOTP", req, {
        headers : {
          Authorization : "Bearer " + Global.apiUser.jwt
        }
      });
        if (response.data.status === true) {
          this.otpRef = response.data.refID;
          this.isSendOTP = false;
          this.isVerifyOTP = true;
          this.isShowErrorMessage = false;
          setTimeout(function(){
            vm.showResendOTP = true;
          }, 10000);
        }
        this.clickResend = false;
        this.isProcessingForgotPassword = false;
      } catch (error) {
        this.clickResend = false;
        this.isProcessingForgotPassword = false;
        console.log(error);
      }
    },
    verifyOTP: async function() {
      if (this.otpNumber.length !== 6) return false;
      this.isProcessingForgotPassword = true;
      this.isShowErrorMessage = false;
      let req = {
        refID: this.otpRef,
        otp: this.otpNumber
      }
      var response = await axios.post(Global.apiUrl + "/broker-details/"+this.emailInput+"/verifyOTPAndActive", req, {
        headers : {
          Authorization : "Bearer " + Global.apiUser.jwt
        }
      });
      if (response.data.success === true) {
        this.isVerifyOTP = false;
        this.isVerifyOTPSuccess = true;
      } else {
        this.isShowErrorMessage = true;
      }
      this.isProcessingForgotPassword = false;
    },
    setNewPassword: function() {
      this.isVerifyOTPSuccess = false;
      this.isSetNewPassword = true;
    },
    confirmedNewPassword: async function() {
      this.isShowErrorMessage = false;
      if (this.validatePassword(this.newPassword, this.confirmNewPassword) == false) {
        this.isShowErrorMessage = true;
        return false;
      }

      this.logActivity(this.emailInput, "Create new password", "Submit change password", this.encryptedId);
      this.isProcessingForgotPassword = true;
      var response = await axios.post(Global.apiUrl + "/broker-details/resetPassword", 
      {
          password: this.newPassword,
          email: this.emailInput
      }, {
        headers : {
          Authorization : "Bearer " + Global.apiUser.jwt
        }
      });
      if(response.data.success){
        this.isSetNewPassword = false;
        this.isSetNewPasswordCompleted = true;
      }else{
        this.isShowErrorMessage = true;
      }
      this.isProcessingForgotPassword = false;
    }
  },
  watch: {
    otpNumber: function(value) {
      this.otpNumber = value.replace(/[^0-9]/g, '');
    }
  }
};
</script>

<i18n>
{
  "en":{
    "welcome": "Welcome!",
    "pleaseSignIn": "<p>Please signin to <strong>Roojai Agent Portal</strong> and make your day.</p>",
    "username": "Username / Email",
    "password": "Password",
    "signin": "Sign In",
    "forgotPassword": "Forgot your password?",
    "forgotPasswordDesc": "<small>No problem! We'll send you a link to reset it. Enter the email address you use to sign in to <strong>Agent portal</strong>.</small>",
    "sendResetLink": "Send reset link",
    "checkYourInbox": "Check your inbox",
    "checkYourInboxDesc": "We've just emailed instructions and a reset password link to <strong>{email}</strong>. It might take a few minutes to arrive.",
    "backToSignin": "Back to sign-in",
    "errorMessage": "An error occurred, Please try again.",
    "activateNewUser": "Activate new user",
    "activateNewUserDesc": "<small>Please input your user email</small>",
    "getStart": "GET START",
    "otpVerification": "OTP Verification",
    "otpVerificationDesc": "<small>Choose your OTP destination Where do you want to recieve your OTP code?</small>",
    "sendOTP": "SEND OTP",
    "verifyOTP": "VERIFY OTP",
    "resendOTP": "RESEND OTP",
    "verifyOTPDesc": "<small>Your reference number (Ref no.): <strong>{otpRef}</strong></small>",
    "otpNumber": "OTP Number",
    "verifyOTPSuccessDesc": "<small>You have successfully authenticated via OTP</small>",
    "setNewPassword": "SET NEW PASSWORD",
    "createNewPassword": "Create a new password",
    "createNewPasswordDesc": "<small>Use a minimum of 12 characters, including uppercase lette, lowercase letter and numbers</small>",
    "confirmPassword": "Confirm password"
  },
  "th":{
    "welcome": "ยินดีต้อนรับ",
    "pleaseSignIn": "<p>กรุณาเข้าสู่ระบบ <strong>เอเจ้นท์</strong> และเริ่มการขาย</p>",
    "username": "ชื่อผู้ใช้ / อีเมล์",
    "password": "รหัสผ่าน",
    "signin": "เข้าสู่ระบบ",
    "forgotPassword": "ลืมรหัสผ่าน",
    "forgotPasswordDesc": "<small>ไม่มีปัญหา เราจะส่งลิงก์รีเซ็ตรหัสผ่านไปให้ท่าน โปรดระบุอีเมลที่ท่านใช้เข้าสู่ระบบ <strong>Agent portal</strong>.</small>",
    "sendResetLink": "ส่งลิงก์รีเซ็ตรหัสผ่าน",
    "checkYourInbox": "ตรวจสอบกล่องจดหมายของคุณ",
    "checkYourInboxDesc": "เราเพิ่งส่งอีเมลแจ้งขั้นตอนพร้อมลิงก์สำหรับรีเซ็ตรหัสผ่านไปที่ <strong>{email}</strong> ทั้งนี้อาจใช้เวลาสักครู่ก่อนที่ท่านจะได้รับอีเมลดังกล่าว",
    "backToSignin": "กลับไปที่ลงชื่อเข้าใช้",
    "errorMessage": "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
    "activateNewUser": "เปิดใช้งานผู้ใช้ใหม่",
    "activateNewUserDesc": "<small>กรุณาใส่อีเมลของคุณ</small>",
    "getStart": "เริ่มต้น",
    "otpVerification": "การตรวจสอบรหัส OTP",
    "otpVerificationDesc": "<small>เลือกช่องทางการรับ OTP ของคุณ คุณต้องการรับรหัส OTP ช่องทางใด</small>",
    "sendOTP": "ส่งรหัสยืนยัน",
    "verifyOTP": "ยืนยัน OTP",
    "resendOTP": "ส่ง OTP อีกครั้ง",
    "verifyOTPDesc": "<small>หมายเลขอ้างอิงของคุณ (หมายเลขอ้างอิง): <strong>{otpRef}</strong></small>",
    "otpNumber": "หมายเลข OTP",
    "verifyOTPSuccessDesc": "<small>คุณตรวจสอบสิทธิ์ผ่าน OTP สำเร็จแล้ว</small>",
    "setNewPassword": "ตั้งรหัสผ่านใหม่",
    "createNewPassword": "สร้างรหัสผ่านใหม่",
    "createNewPasswordDesc": "<small>ใช้อักขระอย่างน้อย 12 ตัว รวมทั้งอักษรตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก และตัวเลข</small>",
    "confirmPassword": "ยืนยันรหัสผ่าน"
  },
  "zh":{
    "welcome": "Welcome!",
    "pleaseSignIn": "<p>Please signin to <strong>Roojai Agent Portal</strong> and make your day.</p>",
    "username": "Username / Email",
    "password": "Password",
    "signin": "Sign In",
    "forgotPassword": "Forgot your password?",
    "forgotPasswordDesc": "<small>No problem! We'll send you a link to reset it. Enter the email address you use to sign in to <strong>Agent portal</strong>.</small>",
    "sendResetLink": "Send reset link",
    "checkYourInbox": "Check your inbox",
    "checkYourInboxDesc": "We've just emailed instructions and a reset password link to <strong>{email}</strong> . It might take a few minutes to arrive.",
    "backToSignin": "Back to sign-in",
    "errorMessage": "An error occurred, Please try again.",
    "activateNewUser": "Activate new user",
    "activateNewUserDesc": "<small>Please input your user email</small>",
    "getStart": "GET START",
    "otpVerification": "OTP Verification",
    "otpVerificationDesc": "<small>Choose your OTP destination Where do you want to recieve your OTP code?</small>",
    "sendOTP": "SEND OTP",
    "verifyOTP": "VERIFY OTP",
    "resendOTP": "RESEND OTP",
    "verifyOTPDesc": "<small>Your reference number (Ref no.): <strong>{otpRef}</strong></small>",
    "otpNumber": "OTP Number",
    "verifyOTPSuccessDesc": "<small>You have successfully authenticated via OTP</small>",
    "setNewPassword": "SET NEW PASSWORD",
    "createNewPassword": "Create a new password",
    "createNewPasswordDesc": "<small>Use a minimum of 12 characters, including uppercase lette, lowercase letter and numbers</small>",
    "confirmPassword": "Confirm password"
  }
}
</i18n>
